import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

const AccordionList = ({ bordered, classNames, list }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveItemIndex(index !== activeItemIndex ? index : null);
  };

  const handleItemKeyDown = (event, index) => {
    if (event.keyCode === 13) {
      setActiveItemIndex(index !== activeItemIndex ? index : null);
    }
  };

  return (
    <div className={`accordion-list${classNames ? ` ${classNames}` : ''}${bordered ? ' accordion-list--bordered' : ''}`}>
      {Array.isArray(list) && list.map((item, index) => (
        <div
          key={index}
          className={`accordion-list__item${activeItemIndex === index ? ' accordion-list__item--is-active' : ''}`}
          role="button"
          tabIndex={0}
          onClick={() => handleItemClick(index)}
          onKeyDown={event => handleItemKeyDown(event, index)}
        >
          {item?.title && (
            <div className="accordion-list__item-title">
              <FormattedMessage id={item.title} />
            </div>
          )}
          {item?.description && (
            <div className="accordion-list__item-description">
              <FormattedMessage id={item.description} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};


AccordionList.propTypes = {
  bordered: PropTypes.bool,
  classNames: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  }))
};

AccordionList.defaultProps = {
  bordered: false,
  classNames: null,
  list: null
};

export default AccordionList;
