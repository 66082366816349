import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper';
import { FormattedMessage} from 'react-intl';

import 'swiper/swiper.scss';
import './styles.scss';

SwiperCore.use([EffectFade]);

const PhoneStepsSliders = ({ children, invert, light, phoneSlides, stepsSlides }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneSlider, setPhoneSlider] = useState(null);
  const [stepsSlider, setStepsSlider] = useState(null);

  const handleStepClick = (index) => {
    if (currentStep !== index) {
      setCurrentStep(index);
    }
  };

  const handleStepKeyDown = (event, index) => {
    if (event.keyCode === 13 && currentStep !== index) {
      setCurrentStep(index);
    }
  };

  const handleStepSlideChange = (swiperEl) => {
    setCurrentStep(swiperEl.activeIndex + 1);
  };

  const handleNavigationClick = (index) => {
    if (index !== currentStep) {
      setCurrentStep(index);
    }
  };

  const handleNavigationKeyDown = (event, index) => {
    if (event.keyCode === 13 && currentStep !== index) {
      stepsSlider.slideTo(index - 1);
    }
  };

  const handlePhoneSlideChange = (swiperEl) => {
    setCurrentStep(swiperEl.activeIndex + 1);
  };

  useEffect(() => {
    const index = currentStep - 1;

    if (stepsSlider && stepsSlider.activeIndex !== index) {
      stepsSlider.slideTo(index);
    }

    if (phoneSlider && phoneSlider.activeIndex !== index) {
      phoneSlider.slideTo(index);
    }
  }, [currentStep, phoneSlider, stepsSlider]);

  const phoneSliderClassnames = invert ? 'col col-12 col-md-5 col-lg-4 col-xl-3 d-flex justify-content-start order-md-2' : 'col col-12 col-md-5 offset-md-1 col-lg-4 offset-lg-2 col-xl-3 offset-xl-3 d-flex justify-content-end';

  const stepsSliderClassnames = invert ? 'col col-12 col-md-6 offset-md-1 col-lg-4 offset-lg-3 order-md-1' : 'col col-12 col-md-6 col-lg-4';

  return (
    <div className="row">
      <div className={phoneSliderClassnames}>
        {Array.isArray(phoneSlides) && (
          <Swiper
            spaceBetween={24}
            slidesPerView="1"
            className="phone-steps-sliders__phone-slider"
            onInit={swiper => setPhoneSlider(swiper)}
            onSlideChange={handlePhoneSlideChange}
            effect="fade"
          >
            {phoneSlides.map((slide, index) => (
              <SwiperSlide key={index}>
                <img
                  src={slide}
                  alt="YouShip App"
                  className="phone-steps-sliders__phone-image"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <div className={stepsSliderClassnames}>
        {Array.isArray(stepsSlides) && (
          <Swiper
            spaceBetween={24}
            slidesPerView="auto"
            className="phone-steps-sliders__steps-slider"
            breakpoints={{
              768: {
                allowTouchMove: false
              }
            }}
            onInit={swiper => setStepsSlider(swiper)}
            onSlideChange={handleStepSlideChange}
          >
            {stepsSlides.map(step => (
              <SwiperSlide
                className="phone-steps-sliders__steps-slider-slide"
                key={step.index}
              >
                <div
                  className={`phone-steps-sliders__step${step.index === currentStep ? ' phone-steps-sliders__step--is-active' : ''}${light ? ' phone-steps-sliders__step--light' : ''}`}
                  onClick={() => handleStepClick(step.index)}
                  onKeyDown={event => handleStepKeyDown(event, step.index)}
                  role="button"
                  tabIndex={0}
                >
                  <div className="phone-steps-sliders__step-number">
                    {step.index}
                  </div>
                  <div className="phone-steps-sliders__step-text">
                    <div className="phone-steps-sliders__step-title">
                      <FormattedMessage id={step.title} />
                    </div>
                    <div className="phone-steps-sliders__step-description">
                      <FormattedMessage id={step.description} />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {!!children && (
          <div className="d-none d-md-block phone-steps-sliders__children">
            {children}
          </div>
        )}
        {!!stepsSlider && (
          <div className="phone-steps-sliders__navigation d-md-none">
            {stepsSlides.map(step => (
              <div
                className={`phone-steps-sliders__navigation-item${step.index === currentStep ? ' phone-steps-sliders__navigation-item--is-active' : ''}${light ? ' phone-steps-sliders__navigation-item--light' : ''}`}
                key={step.index}
                onClick={() => handleNavigationClick(step.index)}
                onKeyDown={event => handleNavigationKeyDown(event, step.index)}
                role="button"
                tabIndex={0}
                aria-label={`Navigate to step ${step.index}`}
              />
            ))}
          </div>
        )}
        {!!children && (
          <div className="d-block d-md-none phone-steps-sliders__children">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

PhoneStepsSliders.propTypes = {
  children: PropTypes.element,
  invert: PropTypes.bool,
  light: PropTypes.bool,
  phoneSlides: PropTypes.arrayOf(PropTypes.string),
  stepsSlides: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string
  }))
};

PhoneStepsSliders.defaultProps = {
  children: null,
  invert: false,
  light: false,
  phoneSlides: null,
  stepsSlides: null
};

export default PhoneStepsSliders;
