import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@youship/components/objects/button';
import PhoneStepsSlider from 'components/phone-steps-sliders';

import stepSlide1Image from 'images/how-it-works/stores-list.png';
import stepSlide2Image from 'images/how-it-works/stores-ordering.png';
import stepSlide3Image from 'images/how-it-works/stores-track.png';
import stepSlide4Image from 'images/how-it-works/stores-payment.png';

import './styles.scss';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const STEPS_SLIDES = [
  {
    index: 1,
    title: 'works.receive.s1.title',
    description: 'works.receive.s1.description'
  },
  {
    index: 2,
    title: 'works.receive.s2.title',
    description: 'works.receive.s2.description'
  },
  {
    index: 3,
    title: 'works.receive.s3.title',
    description: 'works.receive.s3.description'
  },
  {
    index: 4,
    title: 'works.receive.s4.title',
    description: 'works.receive.s4.description'
  }
];

const PHONE_SLIDES = [
  stepSlide1Image,
  stepSlide2Image,
  stepSlide3Image,
  stepSlide4Image
];

const HowItWorksBuy = () => {
  const intl = useIntl();
  const stepsSlides = STEPS_SLIDES;
  const phoneSlides = PHONE_SLIDES;

  return (
    <div className="how-it-works-buy">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col col-12 col-md-6">
            <h6 className="how-it-works-buy__title">
              <FormattedMessage id="works.receive.title" />
            </h6>
            <h2 className="h2--display how-it-works-buy__description">
              <FormattedMessage id="works.receive.subtitle" />
            </h2>
          </div>
        </div>
        <PhoneStepsSlider
          invert
          light
          phoneSlides={phoneSlides}
          stepsSlides={stepsSlides}
        >
          <div className="how-it-works-buy__action">
            <div className="how-it-works-buy__action-title">
              <FormattedMessage id="works.receive.action.title" />
            </div>
            <div className="how-it-works-buy__action-description">
              <FormattedMessage id="works.receive.action.description" />
            </div>
            <Button
              external
              linkProps={{ to: WEB_APP_URL }}
              noArrow
              noNewTab
              outline
              small
              text={intl.formatMessage({ id: 'works.receive.action.btn' })}
            />
          </div>
        </PhoneStepsSlider>
      </div>
    </div>
  );
};

export default HowItWorksBuy;
