import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@youship/components/objects/button';

import PhoneStepsSlider from 'components/phone-steps-sliders';

import p1Image from 'images/how-it-works/delivery/express.png';
import p2Image from 'images/how-it-works/delivery/fast-intercity.png';
import p3Image from 'images/how-it-works/delivery/dedicated.png';
import p4Image from 'images/how-it-works/delivery/fast.png';

import stepSlide1Image from 'images/how-it-works/addresses.png';
import stepSlide2Image from 'images/how-it-works/shipment.png';
import stepSlide3Image from 'images/how-it-works/payment.png';
import stepSlide4Image from 'images/how-it-works/real-time-tracking.png';

import './styles.scss';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const STEPS_SLIDES = [
  {
    index: 1,
    title: 'works.send.s1.title',
    description: 'works.send.s1.description'
  },
  {
    index: 2,
    title: 'works.send.s2.title',
    description: 'works.send.s2.description'
  },
  {
    index: 3,
    title: 'works.send.s3.title',
    description: 'works.send.s3.description'
  },
  {
    index: 4,
    title: 'works.send.s4.title',
    description: 'works.send.s4.description'
  }
];

const PHONE_SLIDES = [
  stepSlide1Image,
  stepSlide2Image,
  stepSlide3Image,
  stepSlide4Image
];

const HowItWorksOrder = () => {
  const intl = useIntl();
  const stepsSlides = STEPS_SLIDES;
  const phoneSlides = PHONE_SLIDES;

  return (
    <div className="how-it-works-order">
      <div className="container">
        <h6 className="how-it-works-order__title">
          <FormattedMessage id="works.send.title" />
        </h6>
        <h2 className="h2--display how-it-works-order__description">
          <FormattedMessage id="works.send.subtitle" />
        </h2>
        <PhoneStepsSlider
          phoneSlides={phoneSlides}
          stepsSlides={stepsSlides}
        >
          <div className="how-it-works-order__action">
            <div className="how-it-works-order__action-title">
              <FormattedMessage id="works.send.action.title" />
            </div>
            <div className="how-it-works-order__action-description">
              <FormattedMessage id="works.send.action.description" />
            </div>
            <Button
              external
              inverted
              linkProps={{ to: `${WEB_APP_URL}/new-order` }}
              noArrow
              noNewTab
              outline
              small
              text={intl.formatMessage({ id: 'works.send.action.btn' })}
            />
          </div>
        </PhoneStepsSlider>
        <div className="how-it-works-order__separator" />
        <div className="row">
          <div className="col col-12 offset-0 col-lg-4 offset-lg-1">
            <div className="how-it-works-order__delivery-title">
              <FormattedMessage id="works.packages.title" />
            </div>
            <div className="how-it-works-order__delivery-description">
              <FormattedMessage id="works.packages.subtitle" />
            </div>
          </div>
          <div className="col col-12 col-lg-7">
            <div className="row">
              <div className="col col-12 col-md-6">
                <div className="how-it-works-order__item how-it-works-order__item--margin">
                  <div className="how-it-works-order__item-image-wrapper">
                    <img
                      alt=""
                      className="how-it-works-order__item-image"
                      src={p1Image}
                    />
                  </div>
                  <div className="how-it-works-order__item-text">
                    <div className="how-it-works-order__item-title">
                      <FormattedMessage id="works.packages.p1.title" />
                    </div>
                    <div className="how-it-works-order__item-description">
                      <FormattedMessage id="works.packages.p1.description" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-6">
                <div className="how-it-works-order__item how-it-works-order__item--margin">
                  <div className="how-it-works-order__item-image-wrapper">
                    <img
                      alt=""
                      className="how-it-works-order__item-image"
                      src={p2Image}
                    />
                  </div>
                  <div className="how-it-works-order__item-text">
                    <div className="how-it-works-order__item-title">
                      <FormattedMessage id="works.packages.p2.title" />
                    </div>
                    <div className="how-it-works-order__item-description">
                      <FormattedMessage id="works.packages.p2.description" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-6">
                <div className="how-it-works-order__item">
                  <div className="how-it-works-order__item-image-wrapper">
                    <img
                      alt=""
                      className="how-it-works-order__item-image"
                      src={p3Image}
                    />
                  </div>
                  <div className="how-it-works-order__item-text">
                    <div className="how-it-works-order__item-title">
                      <FormattedMessage id="works.packages.p3.title" />
                    </div>
                    <div className="how-it-works-order__item-description">
                      <FormattedMessage id="works.packages.p3.description" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-6">
                <div className="how-it-works-order__item how-it-works-order__item--no-margin">
                  <div className="how-it-works-order__item-image-wrapper">
                    <img
                      alt=""
                      className="how-it-works-order__item-image"
                      src={p4Image}
                    />
                  </div>
                  <div className="how-it-works-order__item-text">
                    <div className="how-it-works-order__item-title">
                      <FormattedMessage id="works.packages.p4.title" />
                    </div>
                    <div className="how-it-works-order__item-description">
                      <FormattedMessage id="works.packages.p4.description" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksOrder;
