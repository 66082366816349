import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@youship/components/objects/button';
import Img from 'components/image';

import './styles.scss';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const headerImage1 = 'home/header_image_1.jpg';
const headerImage2 = 'home/header_image_2.jpg';

const HowItWorksHeader = () => {
  const intl = useIntl();

  return (
    <div className="how-it-works-header">
      <div className="container">
        <div className="row how-it-works-header__row d-flex align-items-center">
          <div className="col col-12 col-md-6 offset-md-2 order-md-3">
            <div className="how-it-works-header__text">
              <h1 className="h1--display how-it-works-header__title">
                <FormattedMessage id="works.header.title" />
              </h1>
              <div className="how-it-works-header__description">
                <FormattedMessage id="works.header.subtitle" />
              </div>
              <div className="how-it-works-header__buttons">
                <Button
                  classNames="how-it-works-header__button"
                  context="primary"
                  external
                  linkProps={{ to: `${WEB_APP_URL}/new-order` }}
                  noArrow
                  noNewTab
                  small
                  text={intl.formatMessage({ id: 'works.header.send' })}
                />
                <Button
                  classNames="how-it-works-header__button"
                  context="primary"
                  external
                  linkProps={{ to: WEB_APP_URL }}
                  noArrow
                  noNewTab
                  outline
                  small
                  text={intl.formatMessage({ id: 'works.header.receive' })}
                />
              </div>
            </div>
          </div>
          <div className="col col-10 col-md-4 col-xl-4 order-md-1">
            <div className="d-none d-md-block how-it-works-header__background-element" />
            <Img
              alt="Header 1"
              classNames="how-it-works-header__image"
              src={headerImage1}
            />
          </div>
          <div className="col col-10 offset-2 col-md-3 offset-md-2 col-xl-3 offset-xl-2 order-md-2 how-it-works-header__image-column">
            <Img
              alt="Header 2"
              classNames="how-it-works-header__image how-it-works-header__image--small"
              src={headerImage2}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksHeader;
